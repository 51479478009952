import Container from "../Backend/Grid/Container"
import Row from "../Backend/Grid/Row"
import Column from "../Backend/Grid/Column"
import { useEffect, useState } from "react"
import axios from "axios"
import Text from "./Text"

const Footer = () => {
    const [note, setNote] = useState<string>('');

    useEffect(() => {
        const fetchNote = async () => {
            try {
                const note = await axios.get(route('notes.get'))

                if (note.status === 200) {
                    setNote(note.data.data.message)
                }
            } catch (error) {

            }
        }
        fetchNote()
    }, []);

    const checkNote = () => {
        if (!note || note == '<p></p>' || note == '<p>&nbsp;</p>'){
            return true;
        } else {
            return false;
        }
    }

    return (
        <footer className="bg-i-navy pt-8 lg:pb-[72px] pb-10">
            <Container>
                <Row className="gap-4">
                    <Column>
                        <img
                            loading="lazy"
                            className="md:m-0 m-auto"
                            src="/storage/img/logos/unidevelopment-logo.svg"
                            alt="Unidevelopment"
                        />
                    </Column>

                    <Column>
                        <Row className="lg:gap-0 sm:gap-y-4 sm:gap-x-0 gap-8">
                            <Column xl={3} lg={3} md={6} sm={6} className="lg:order-none">
                                <p className="text-white text-20 leading-7 font-medium mb-4">
                                    Biuro sprzedaży mieszkań
                                </p>
                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    ul. Listopadowa 5
                                </p>
                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    26-601 Radom
                                </p>
                                <p className="text-white text-20 leading-7 font-medium mb-4 mt-4">
                                    Inspektor Ochrony Danych
                                </p>
                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    Jakub Wezgraj
                                </p>
                                <a href="mailto:ochronadanych@unidevelopment.pl" className="leading-6 text-4 text-i-blue tracking-[0.012em] border-b border-transparent hover:text-i-yellow hover:border-i-yellow hover:border-b transition-all ease-in-out duration-300">
                                    ochronadanych@unidevelopment.pl
                                </a>
                            </Column>

                            <Column xl={3} lg={3} md={6} sm={6} className="lg:order-none order-1">
                                <p className="text-white text-20 leading-7 font-medium mb-4">
                                    Dane kontaktowe
                                </p>

                                <p className="text-white text-14 leading-4 font-semibold tracking-[0.005]">
                                    Telefon
                                </p>

                                <a
                                    href="+48483770877"
                                    className="leading-6 text-4 text-white tracking-[0.012em] border-b border-transparent hover:text-i-yellow hover:border-i-yellow hover:border-b transition-all ease-in-out duration-300"
                                >
                                    (+48) 48 377 08 77
                                </a>

                                <p className="text-white text-14 leading-4 font-semibold tracking-[0.005] mt-4">
                                    E-mail
                                </p>

                                <a
                                    href="mailto:idea@unidevelopment.pl"
                                    className="leading-6 text-4 text-i-blue tracking-[0.012em] border-b border-transparent hover:text-i-yellow hover:border-i-yellow hover:border-b transition-all ease-in-out duration-300"
                                >
                                    idea@unidevelopment.pl
                                </a>
                            </Column>

                            <Column xl={2} lg={2} md={6} sm={6} className="lg:order-none order-2">
                                <p className="text-white text-20 leading-7 font-medium mb-4">
                                    Godziny pracy
                                </p>

                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    poniedziałek - piątek
                                </p>
                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    9:00 – 18:00
                                </p>
                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    sobota
                                </p>
                                <p className="leading-6 text-16 text-white tracking-[0.012em]">
                                    9:00 – 14:00
                                </p>
                            </Column>

                            <Column xl={3} lg={3} md={6} sm={6} className={`lg:order-none order-3 ${checkNote() && 'lg:block hidden'}`}>
                                <Text heading='h4' color="i-yellow">
                                    <div className="font-semibold">
                                        {note}
                                    </div>
                                </Text>
                            </Column>

                            <Column xl={1} lg={1} md={12} className="lg:order-none order-5">
                                <div className="gap-4 flex lg:flex-col lg:justify-normal justify-center md:items-end">
                                    <a title="Facebook" href="https://www.facebook.com/Unidevelopment" target="_blank" className="hover:-translate-y-1 transition-transform ease-in-out duration-200">
                                        <img
                                            loading="lazy"
                                            className="w-8 h-8"
                                            src="/storage/img/icons/Facebook.svg"
                                            alt="Facebook"
                                        />
                                    </a>
                                    <a title="LinkedIn" href="https://www.linkedin.com/company/unidevelopment" target="_blank" className="hover:-translate-y-1 transition-transform ease-in-out duration-200">
                                        <img
                                            loading="lazy"
                                            className="w-8 h-8"
                                            src="/storage/img/icons/LinkedIn.svg"
                                            alt="LinkedIn"
                                        />
                                    </a>
                                </div>
                            </Column>

                            <Column className="lg:mt-6 lg:order-none order-4">
                                <Row className="justify-center gap-y-3">
                                    <div className="px-3 lg:w-1/4 md:w-1/2 w-full">
                                        <a href="https://unidevelopment.pl/page/polityka-prywatnosci" title="Zapoznaj się z naszą polityką prywatności" target="_blank" className="leading-6 text-16 text-white tracking-[0.012em] relative before:absolute before:w-full before:h-px before:bg-i-yellow before:-bottom-0.5 before:scale-x-0 hover:before:scale-x-100 before:transition-all before:duration-200 before:ease-in-out hover:text-i-yellow transition-all ease-in-out duration-200">
                                            <span>
                                                Polityka Prywatności
                                            </span>
                                        </a>
                                    </div>
                                    <div className="px-3 lg:w-1/4 md:w-1/2 w-full">
                                        <a href="https://unidevelopment.pl/page/polityka-plikow-cookies" title="Zapoznaj się z naszą polityką cookie" target="_blank" className="leading-6 text-16 text-white tracking-[0.012em] relative before:absolute before:w-full before:h-px before:bg-i-yellow before:-bottom-0.5 before:scale-x-0 hover:before:scale-x-100 before:transition-all before:duration-200 before:ease-in-out hover:text-i-yellow transition-all ease-in-out duration-200">
                                            <span>
                                                Polityka Cookie
                                            </span>
                                        </a>
                                    </div>
                                </Row>
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;