const LinksArrayFrontDesktop = [
    {
        url: route('front.discover-estates'),
        title: "Osiedle Idea",
    },
    {
        url: route('front.apartments.search'),
        title: "Mieszkania",
    },
    {
        url: route('front.news'),
        title: "Aktualności",
    },
    {
        url: route('front.diary-of-build'),
        title: "Dziennik budowy",
    },
    {
        url: route('front.gallery'),
        title: "Galeria",
    },
    {
        url: route('front.arrangements'),
        title: "Wnętrza",
    },
    {
        url: route('front.investor'),
        title: "Inwestor",
    },
    {
        url: route('front.contact'),
        title: "Kontakt",
    },
    {
        url: route('front.apartments'),
        title: "Lokale usługowe",
    },
    {
        url: '/storage/idea_folder.pdf',
        title: "FOLDER",
        target: "blank"
    },
];

export default LinksArrayFrontDesktop;
